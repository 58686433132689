var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form-wizard', {
    staticClass: "wizard-vertical mb-3",
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "layout": "vertical",
      "finish-button-text": "Finalizar",
      "back-button-text": "Anterior",
      "next-button-text": "Próximo"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Dados da Escola"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Dados da Escola ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" Insira os dados da Escola ")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nome da Escola",
      "label-for": "v-username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-username",
      "placeholder": "Insira o nome aqui"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail",
      "label-for": "v-email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-email",
      "type": "email",
      "placeholder": "exemplo@email.com"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telefone",
      "label-for": "v-username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-username",
      "placeholder": "Insira o número aqui"
    },
    model: {
      value: _vm.form.telephone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "telephone", $$v);
      },
      expression: "form.telephone"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "v-greId",
      "label": "Gerência Regional de Educação (GRE)"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "greId",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "selectable": function selectable(option) {
        return option.value != null;
      },
      "options": _vm.greOptions,
      "label": "text"
    },
    model: {
      value: _vm.form.greId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "greId", $$v);
      },
      expression: "form.greId"
    }
  })], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Diretoria"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Diretoria ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Insira as informações do Diretor")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nome do Diretor",
      "label-for": "v-first-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-first-name",
      "placeholder": ""
    },
    model: {
      value: _vm.form.nameDirector,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameDirector", $$v);
      },
      expression: "form.nameDirector"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail",
      "label-for": "v-last-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-last-name",
      "placeholder": ""
    },
    model: {
      value: _vm.form.emailDirector,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "emailDirector", $$v);
      },
      expression: "form.emailDirector"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "CPF",
      "label-for": "v-last-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-last-name",
      "placeholder": ""
    },
    model: {
      value: _vm.form.cpfDirector,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cpfDirector", $$v);
      },
      expression: "form.cpfDirector"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Celular",
      "label-for": "v-last-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-last-name",
      "placeholder": ""
    },
    model: {
      value: _vm.form.cellPhoneDirector,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cellPhoneDirector", $$v);
      },
      expression: "form.cellPhoneDirector"
    }
  })], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Endereço"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Endereço ")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Insira os dados sobre localização")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Endereço",
      "label-for": "v-address"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-address",
      "placeholder": ""
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bairro",
      "label-for": "v-landmark"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-landmark",
      "placeholder": ""
    },
    model: {
      value: _vm.form.neighborhood,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "neighborhood", $$v);
      },
      expression: "form.neighborhood"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "CEP",
      "label-for": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-pincode",
      "placeholder": ""
    },
    model: {
      value: _vm.form.postalCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postalCode", $$v);
      },
      expression: "form.postalCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cidade",
      "label-for": "v-city"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-city",
      "placeholder": ""
    },
    model: {
      value: _vm.form.city,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }