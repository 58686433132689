<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Finalizar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account datails tab -->
      <tab-content title="Dados da Escola">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Dados da Escola
            </h5>
            <small class="text-muted"> Insira os dados da Escola </small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nome da Escola"
              label-for="v-username"
            >
              <b-form-input
                id="v-username"
                v-model="form.name"
                placeholder="Insira o nome aqui"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="E-mail"
              label-for="v-email"
            >
              <b-form-input
                id="v-email"
                v-model="form.email"
                type="email"
                placeholder="exemplo@email.com"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Telefone"
              label-for="v-username"
            >
              <b-form-input
                id="v-username"
                v-model="form.telephone"
                placeholder="Insira o número aqui"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="v-greId"
              label="Gerência Regional de Educação (GRE)"
            >
              <v-select
                id="greId"
                v-model="form.greId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :selectable="option => option.value != null"
                :options="greOptions"
                label="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- personal info tab -->
      <tab-content title="Diretoria">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Diretoria
            </h5>
            <small class="text-muted">Insira as informações do Diretor</small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nome do Diretor"
              label-for="v-first-name"
            >
              <b-form-input
                id="v-first-name"
                v-model="form.nameDirector"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="E-mail"
              label-for="v-last-name"
            >
              <b-form-input
                id="v-last-name"
                v-model="form.emailDirector"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="CPF"
              label-for="v-last-name"
            >
              <b-form-input
                id="v-last-name"
                v-model="form.cpfDirector"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Celular"
              label-for="v-last-name"
            >
              <b-form-input
                id="v-last-name"
                v-model="form.cellPhoneDirector"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- address -->
      <tab-content title="Endereço">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Endereço
            </h5>
            <small class="text-muted">Insira os dados sobre localização</small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Endereço"
              label-for="v-address"
            >
              <b-form-input
                id="v-address"
                v-model="form.address"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Bairro"
              label-for="v-landmark"
            >
              <b-form-input
                id="v-landmark"
                v-model="form.neighborhood"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="CEP"
              label-for=""
            >
              <b-form-input
                id="v-pincode"
                v-model="form.postalCode"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cidade"
              label-for="v-city"
            >
              <b-form-input
                id="v-city"
                v-model="form.city"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- social link -->
      <!-- <tab-content title="Turmas">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Social Links</h5>
            <small class="text-muted">Insira informações de turmas</small>
          </b-col>
        </b-row>
      </tab-content> -->
    </form-wizard>
  </div>
</template>

<script>
import schoolService from '@/services/schoolService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
BCol, BFormGroup, BFormInput, BRow
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: ['id'],
  data() {
    return {
      selectedContry: 'select_value',
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      greOptions: [
        { value: null, text: 'Nenhuma GRE Selecionada' },
        { value: '61eb4207-d6ce-4b53-b3c0-373150de9816', text: 'GRE Sul' },
        { value: 'f925c7cc-cdcb-43de-a982-62c9c4a4118b', text: 'GRE Norte' },
      ],

      form: {
        id: null,
        name: null,
        email: null,
        greId: null, // Mock até criar GRE
        telephone: null,
        nameDirector: null,
        emailDirector: null,
        cpfDirector: null,
        cellPhoneDirector: null,
        address: null,
        neighborhood: null,
        postalCode: null,
        city: null,
      },
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getSchoolInfo()
    }
  },
  methods: {
    async formSubmitted() {
      showSpinner()
      if (this.$route.params.id) {
        this.fetchedSchools = await schoolService
          .update({ ...this.form, greId: this.form.greId.value })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/EscolasLista')
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Erro ao criar escola. Verifique os campos e tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            hideSpinner()
          })
      } else {
        this.fetchedSchools = await schoolService
          .create({ ...this.form, greId: this.form.greId.value })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/EscolasLista')
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Erro ao criar escola. Verifique os campos e tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            hideSpinner()
          })
      }
    },
    async getSchoolInfo() {
      try {
        const school = await schoolService.getById(this.$route.params.id)
        this.form = school
      } catch (error) {
        this.$bvToast.toast('Erro ao carregar Escola!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
